import React, { useState } from 'react';
import GM from '../GM';
import Header from '../components/Header';
import UCC from '../components/custom/ucc/UCC';
import Highlights from '../components/custom/highlights/Highlights';

function Component(props) {
    const page = props.page;
    const [address, setAddress] = useState('');

  return (
    <div className="page-wrap">
      <Header 
      address={address}
      setAddress={setAddress}
      />
      {!page.custom
      ?
        <GM 
        apikey={page.api}
        contract={page.contract}
        contractslug={page.contractslug}
        name={page.title}
        bg={`https:${page.dots.fields.file.url}`}
        size={page.size}
        component={page.component}
        gm={page.gm}
        trait={page.trait}
        address={address}
        setAddress={setAddress}
        network={page.network}
        />
      : [
          page.component === 'ucc' ?
          <UCC
            amount={4999}
            apikey={page.api}
            contract={page.contract}
            name={page.title}
            component={page.component}
            bg={`https:${page.dots.fields.file.url}`}
            size={page.size}
          />
          :
          page.component === 'highlights' &&
          <Highlights
            amount={4267}
            apikey={page.api}
            contract={page.contract}
            contractslug={page.contractslug}
            name={page.title}
            component={page.component}
            bg={`https:${page.dots.fields.file.url}`}
            size={page.size}
            address={address}
            setAddress={setAddress}
          />
        ]
      }
    </div>
  );
}

export default Component;