import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import Footer from '../../../components/Footer';
import './ucc.css';
import { ReactComponent as Logo } from './logo.svg';

function UCC({amount, apikey, contract, component, name, bg, size}) {
  const canvasRef = useRef(null);
  const canvas = canvasRef.current;
  let x = 0, y = 0;

  const [tokenId, setTokenId] = useState('');
  const [imageURL, setImageURL] = useState('');
  const [plainImage, setPlainImage] = useState('');
  const [bling, setBling] = useState(false);
  const [error, setError] = useState('');
  const [selectedOptions, setSelectedOptions] = useState('');
  
  const regExp = /^0[0-9].*$/;

  const blings = [
        { id: 0, name: 'Empty', imageName: 'nothing' },
        { id: 1, name: 'Cheer', imageName: 'cheer' },
        { id: 2, name: 'Spooky 1', imageName: 'spooky1' },
        { id: 3, name: 'Spooky 2', imageName: 'spooky2' },
        { id: 4, name: 'Brew Bag', imageName: 'bag-brew' },
        { id: 5, name: 'Slim3 Bag', imageName: 'bag-slim3' },
        { id: 6, name: 'Beer Bag', imageName: 'bag-beer' },
        { id: 7, name: 'Slurb Red', imageName: 'slurb-red' },
        { id: 8, name: 'Slurb Blue', imageName: 'slurb-blue' },
        { id: 9, name: 'CTJR Bandana', imageName: 'ctjr-bandana' },
  ];

  // OPTION SELECTED
  const handleOptionClick = (option) => {
    setSelectedOptions(option);

    canvas.getContext('2d').clearRect(x, y, size, size);
    fetchImage(plainImage, 1, 0);
    
    rerunFetchImage(imageURL,option.imageName);
  };

  // RERUN FOR BLING
  const rerunFetchImage = (newImage,option) => {
      let blingbling = require('../../../images/custom/'+component+'/'+option+'.png')     

      if(blingbling !== null) {
        fetchImage(blingbling, 2, 1);
      }
  } // fetch image

  // GET IMAGE
  let fetchImage = (source, total, current) => {
    return new Promise(resolve => {

      let image = new Image();
      image.src = source;
      image.onload = () => {
        canvas.getContext('2d').globalAlpha = 1;
        canvas.getContext('2d').drawImage(image, x, y, size, size);
        resolve();
      }  
      image.setAttribute('crossorigin', 'anonymous');

      setTimeout(() => {
        setImageURL(image);
      }, 500)
    });
  }

  // SUBMIT
  const handleSubmit = async (e) => {
    e.preventDefault();

    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    ctx.clearRect(x, y, size, size);

    if(tokenId === '') {
        setError('ID cannot be blank');
        setBling(false);
    } else if(regExp.test(tokenId) == true) {
        setError('Remove the 0 at the beginning');
        setBling(false);
    } else if(tokenId > amount) {
        setError('Choose an ID between 1 and '+amount);
        setBling(false);
    } else {
      // GOOD TO GOOOOOO
      try {
        const axiosConfig = {
          method: 'GET',
          headers: {accept: 'application/json', 'X-API-KEY': `${apikey}`}
        }

        const response = await axios.get(`https://api.opensea.io/v2/chain/ethereum/contract/${contract}/nfts/${tokenId}`, axiosConfig);
        
        let data = response.data;
        let imageURL  = data.nft.image_url;
        let image_resized = imageURL.replace('w=500', 'w=' + size);

        setImageURL(image_resized)
        setPlainImage(image_resized)

        fetchImage(image_resized, 1, 0)

        setBling(true);

        setError('');
      } catch (err) {
        console.log(err);
        setImageURL('');
        setError('Error fetching image. Please check the token ID.');
      }
    }
  };

  // DOWNLOAD
  const downloadAsset = () => {
      const dataURL = canvasRef.current.toDataURL('image/png');
      const a = document.createElement('a');
      a.href = dataURL;
      a.download = 'download.png';

      a.click();
  }

  return (
    <div className="gm-wrapper ucc">
      <div className="gm">

        <div className="download-left">
          <div className="ulogo">
            <Logo />
            <span>Bling</span>
          </div>

          <div 
            className="inner top">
            <form id="download-form" name="download-form" onSubmit={handleSubmit}>
              <h2>Find UCC by <strong>Token ID</strong></h2>
              <div className="field number">
                <input
                  name="theid" 
                  id="theid" 
                  placeholder="Token ID" 
                  pattern="\d{1,4}" 
                  maxLength="4"
                  value={tokenId}
                  onChange={(e) => setTokenId(e.target.value)}
                />
              <button className="btn" type="submit"><span>Generate Image</span></button>
              </div>{/* FIELD */}
              {error && <p className="throw_error">{error}</p>}
            </form>{/* FORM */}
            </div>{/* INNER */}

            {imageURL && (
              <div className="inner bottom">
                <h2>Bling out your <strong>Uncanny</strong></h2>

                <div className="options">
                  {blings.map((bling) => (
                    <div
                      key={bling.id}
                      className={`bling ${
                        selectedOptions.id === bling.id
                          ? 'selected'
                          : ''
                      }`}
                      onClick={() => handleOptionClick(bling)}
                    >
                      <div className="img-wrap"><img src={require(`../../../images/custom/${component}/${bling.imageName}.png`)} alt={bling.name} /></div>
                      <p>{bling.name}</p>
                    </div>
                  ))}
                </div>
            </div>
            )}
        </div>{/* LEFT */}

        <div className="canvas-outter-wrap">
          <div id="canvas-wrap">
              <canvas
                ref={canvasRef}
                id="imageCanvas"
                width={size}
                height={size}
                style={{backgroundImage: `url(${bg})`}}
              ></canvas>
            {imageURL && (
              <>
              <img
                crossOrigin="anonymous"
                src={imageURL}
                alt="Token"
                style={{ display: 'none' }}
              />
              </>
            )}
          </div>{/* CANVAS WRAP */}
            {imageURL && (
              <button 
                className="download btn"
                onClick={downloadAsset}
              >Download Image</button>
            )}
        </div>

      </div>{/* GM */}

      <Footer />
    </div>
  )
}

export default UCC;