import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './Pages/home';
import Wallpapers from './Pages/wallpapers';
import Component from './components/Component';
import Wallpaper from './components/Wallpaper';
import { getUserWalletAddress } from './components/Utils';
import './App.css';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faLink, faLinkSlash, faMagnifyingGlass, faMobileScreenButton, faBullhorn } from '@fortawesome/free-solid-svg-icons';
import contentfulClient from './contentful';

library.add(faLink, faLinkSlash, faMagnifyingGlass, faMobileScreenButton, faBullhorn)

function App() {
  const [pages, setPages] = useState([]);
  const [wallpapers, setWallpapers] = useState([]);
  const storedUserAddress = getUserWalletAddress();
  const cacheExp = 60 * 60 * 1000; // 1 hour in milliseconds

  useEffect(() => {
    const fetchContent = async () => {
      await fetchPages();
      await fetchWallpapers();
    };

    const fetchPages = async () => {
      const storageKey = 'pagesData';
      const cachedData = localStorage.getItem(storageKey);
      const cachedTimestamp = localStorage.getItem(`${storageKey}Timestamp`);
      const currentTime = new Date().getTime();

      if (cachedData && cachedTimestamp && (currentTime - cachedTimestamp < cacheExp)) {
        setPages(JSON.parse(cachedData));
      } else {
        try {
          const response = await contentfulClient.getEntries({
            content_type: 'gm',
            order: 'fields.order',
          });
          const newPages = response.items.map(item => ({
            title: item.fields.title,
            slug: item.fields.slug,
            component: item.fields.component,
            size: item.fields.size,
            contractslug: item.fields.contractSlug,
            contract: item.fields.contract,
            api: item.fields.api,
            gm: item.fields.gm,
            trait: item.fields.trait,
            custom: item.fields.custom,
            network: item.fields.network,
            image: item.fields.image,
            dots: item.fields.dots,
          }));
          setPages(newPages);

          localStorage.setItem(storageKey, JSON.stringify(newPages));
          localStorage.setItem(`${storageKey}Timestamp`, currentTime.toString());
        } catch (error) {
          console.error('Error fetching pages from Contentful:', error);
        }
      }
    };

    const fetchWallpapers = async () => {
      const storageKey = 'wallpapersData';
      const cachedData = localStorage.getItem(storageKey);
      const cachedTimestamp = localStorage.getItem(`${storageKey}Timestamp`);
      const currentTime = new Date().getTime();

      if (cachedData && cachedTimestamp && (currentTime - cachedTimestamp < cacheExp)) {
        setWallpapers(JSON.parse(cachedData));
      } else {
        try {
          const response = await contentfulClient.getEntries({
            content_type: 'wallpaper',
            order: 'fields.order',
          });
          const newWallpapers = response.items.map(item => ({
            title: item.fields.title,
            slug: item.fields.slug,
            component: item.fields.component,
            size: item.fields.size,
            total: item.fields.total,
            logo: item.fields.logo,
            contractslug: item.fields.contractslug,
            contract: item.fields.contract,
            api: item.fields.api,
            trait: item.fields.trait,
            network: item.fields.network,
            rares: item.fields.rares,
            oneoffs: item.fields.oneoffs,
            collectionlogo: item.fields.collectionlogo,
            image: item.fields.image,
          }));
          setWallpapers(newWallpapers);

          localStorage.setItem(storageKey, JSON.stringify(newWallpapers));
          localStorage.setItem(`${storageKey}Timestamp`, currentTime.toString());
        } catch (error) {
          console.error('Error fetching wallpapers from Contentful:', error);
        }
      }
    };

    fetchContent();
  }, []);

  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/wallpapers" element={<Wallpapers />} />
  
        {pages.map((page) => {
          return <Route 
            key={page.slug} 
            path={page.slug} 
            element={<Component page={page} storedAddress={storedUserAddress} />} 
          />
        })}

        {wallpapers.map((wallpaper) => {
          return <Route
            key={wallpaper.slug}
            path={'wallpapers/' + wallpaper.slug}
            element={<Wallpaper wallpaper={wallpaper} storedAddress={storedUserAddress} />} 
          />
        })}

      </Routes>
    </Router>
  )
}

export default App;