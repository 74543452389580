import React, { useState, useEffect } from 'react';
import Web3 from 'web3';
import { Connection } from '@solana/web3.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function ConnectButton(props) {
  const [walletConnectBtn, setConnectText] = useState("Connect Wallet");
  const [disconnect, setDisconnect] = useState(false);
  const [network, setNetwork] = useState('ethereum'); // Default network
  const [wallet, setWallet] = useState(null);

  // truncate the wallet address
  const truncateAddress = (address) => {
    if (!address) return '';
    return address.slice(0, 6) + '...' + address.slice(-4);
  };

  // DISCONNECT WALLET
  const disconnectWallet = async () => {
    try {
      setWallet(null);
      localStorage.removeItem('userWalletAddress');
      setConnectText("Connect Wallet");
    } catch (error) {
      alert('Error disconnecting wallet:', error);
    }
  };

  // CONNECT TO ETHEREUM WALLET
  const connectEthereumWallet = async () => {
    if (typeof window.ethereum !== 'undefined') {
      try {
        await window.ethereum.request({ method: 'eth_requestAccounts' });
        const web3 = new Web3(window.ethereum);
        const accounts = await web3.eth.getAccounts();
        const userAddress = accounts[0];
        setWallet(userAddress);
        setConnectText(truncateAddress(userAddress));
        localStorage.setItem('userWalletAddress', userAddress);

        // Only call props.setAddress if it exists
        if (props.setAddress) {
          props.setAddress(userAddress);
        }
      } catch (error) {
        console.error(error);
      }
    } else {
      alert('No Ethereum wallet detected. Please install MetaMask.');
    }
  };

  // CONNECT TO SOLANA WALLET
  const connectSolanaWallet = async () => {
    try {
      const connection = new Connection('https://api.mainnet-beta.solana.com');
      const solana = window.solana;

      if (solana && solana.isPhantom) {
        const resp = await solana.connect();
        const userAddress = resp.publicKey.toString();
        setWallet(userAddress);
        setConnectText(truncateAddress(userAddress));
        localStorage.setItem('userWalletAddress', userAddress);

        // Only call props.setAddress if it exists
        if (props.setAddress) {
          props.setAddress(userAddress);
        }
      } else {
        alert('Please install the Phantom Wallet for Solana.');
      }
    } catch (error) {
      console.error('Error connecting to Solana wallet:', error);
    }
  };

  // CONNECT WALLET BASED ON SELECTED NETWORK
  const connectWallet = async () => {
    if (wallet) {
      setConnectText(truncateAddress(wallet));
    } else {
      if (network === 'ethereum') {
        await connectEthereumWallet();
      } else if (network === 'solana') {
        await connectSolanaWallet();
      }
    }
  };

  // Save selected network to localStorage and disconnect the wallet
  const handleNetworkChange = (e) => {
    const selectedNetwork = e.target.value;
    
    // If a wallet is connected, disconnect it when switching networks
    if (wallet) {
      disconnectWallet();
    }

    setNetwork(selectedNetwork);
    localStorage.setItem('selectedNetwork', selectedNetwork);
  };


  // On component load, check if the wallet and network are stored in localStorage
  useEffect(() => {
    const storedWalletAddress = localStorage.getItem('userWalletAddress');
    const storedNetwork = localStorage.getItem('selectedNetwork') || 'ethereum';

    if (storedWalletAddress) {
      setWallet(storedWalletAddress);
      setConnectText(truncateAddress(storedWalletAddress));
    }

    setNetwork(storedNetwork); // Set network from localStorage
  }, []);

  // WALLET CHANGE LISTENER (ETHEREUM)
  useEffect(() => {
    if (typeof window.ethereum !== 'undefined') {
      window.ethereum.on('accountsChanged', (accounts) => {
        if (accounts.length === 0) {
          disconnectWallet();
        }
      });
    }
  }, []);

  return (
    <>
      {/* Network Selection */}
      <select 
        value={network} 
        onChange={handleNetworkChange} 
        className="network-dropdown btn skinny"
      >
        <option value="ethereum">ETH</option>
        <option value="solana">SOL</option>
      </select>

      {wallet ? (
        <div className="wallet-buttons">
          <button
            className="btn skinny connect-wallet"
            onClick={() => {
              setDisconnect((value) => !value);
            }}
          >
            <FontAwesomeIcon icon="link" />
            {walletConnectBtn}
          </button>
          <button
            className={"btn skinny disconnect-wallet " + (disconnect ? "display" : "")}
            onClick={disconnectWallet}
          >
            <FontAwesomeIcon icon="link-slash" />
            Disconnect
          </button>
        </div>
      ) : (
        <button className="btn skinny connect-wallet" onClick={connectWallet}>
          <FontAwesomeIcon icon="link" />
          {walletConnectBtn}
        </button>
      )}
    </>
  );
}

export default ConnectButton;