import React, { useState, useEffect } from 'react';
import { fetchImage } from '../components/Utils';
import axios from 'axios';

function Asset(props) {
  const [assetTraits, setAssetTraits] = useState('');
  const [error, setError] = useState('');
  const assetID = props.asset.identifier;

  const clickAsset = async (image) => {
    let canvas = document.getElementById('imageCanvas');
    let context = canvas.getContext('2d');  

    try {
      let data;
      if (props.network === "ethereum") {
        const osApiKey = props.apiKey;
        const response = await axios.get(
          `https://api.opensea.io/v2/chain/ethereum/contract/${props.contract}/nfts/${assetID}`,
          { headers: { accept: 'application/json', 'X-API-KEY': osApiKey } }
        );
        data = response.data;
      } else if (props.network === "solana") {
        const mintAddress = props.id;
        const response = await axios.get(`http://localhost:5001/proxy/magic-eden/token?mintAddress=${mintAddress}`);
        data = response.data;
      }

      let imageURL = props.network === 'ethereum' ? data.nft.image_url : data.image;

      if (!props.wallpaper) {
        // Regular NFT display logic
        let image_resized = imageURL.replace('w=500', 'w=' + props.size);
        props.setToggle('image');
        props.setImageURL(image_resized);
        props.setPlainImage(image_resized);
        fetchImage(null, image_resized, null, null,1, 0, context, canvas, props.size, props.setImageURL, props.setPlainImage, null);
      } else {
        // Wallpaper generation logic
        props.setToggle('default');
        let cleanTraitPath = '';
        let oneOffPath = '';

        if (props.rares && props.rares.includes(assetID)) {
          imageURL = require(`../images/wallpapers/${props.component}/bg_${assetID}.jpg`);
          props.setImageURL(imageURL);
          fetchImage(true, imageURL, false, false, 1, 0, context, canvas, props.size, props.setImageURL, props.setPlainImage, null);
        } else {
          const traits = props.network === 'ethereum' ? data.nft.traits : data.attributes;
          traits.forEach((type) => {
            if (props.oneoffs) {
              props.oneoffs.forEach(oneoff => {
                Object.keys(oneoff).forEach(traitType => {
                  if (type.trait_type === traitType) {
                    const ooTraits = oneoff[traitType];
                    Object.keys(ooTraits).forEach(traitName => {
                      if (traitName === type.value) {
                        oneOffPath = require(`../images/wallpapers/${props.component}/${ooTraits[traitName]}.png`);
                      }
                    });
                  }
                });
              });
            }
            if (type.trait_type === props.trait) {
              let cleanTrait = type.value.replace(/\s+/g, '-').toLowerCase();
              props.setSavedTrait(cleanTrait);
              cleanTraitPath = require(`../images/wallpapers/${props.component}/bg_${cleanTrait}.png`);
            }
          });

          props.setImageURL(imageURL);
          fetchImage(false, imageURL, cleanTraitPath, oneOffPath, 1, 0, context, canvas, props.size, props.setImageURL, props.setPlainImage, null);
        }
      }

      // Handle traits
      const traits = props.network === 'ethereum' ? data.nft.traits : data.attributes;
      traits.forEach((type) => {
        let traitType = type.trait_type;
        if (traitType === props.trait) {
          let cleanTrait = type.value.replace(/\s+/g, '-').toLowerCase();
          props.setSavedTrait(cleanTrait);
        }
      });
    } catch (error) {
      setError('Error getting asset details. ' + error);
    }
  };

  return (
    <div 
      onClick={() => clickAsset(props.image)}
      className="asset"
    >
      <img src={props.image} id={assetID} alt="NFT Asset" />
    </div>
  );
}

export default Asset;
