import React, { useState, useEffect } from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Photo from '../components/Photo';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function Wallpapers() {
    const [wallpapers, setWallpapers] = useState([]);

    useEffect(() => {
        const storedWallpapers = localStorage.getItem('wallpapersData');
        if (storedWallpapers) {
            setWallpapers(JSON.parse(storedWallpapers));
        }
    }, []);

    return (
        <div className="page-wrap home">
            <Header />
            <div className="home-inner container">
                <h1 className="home">NFT <strong>Wallpapers</strong></h1>
                <p>Welcome to TokenSnap, your window into the mesmerizing world of NFT art and collectibles. <strong>Choose your collection below to get started!</strong></p>

                <div className="wallpapers">
                    <div className="home-boxes wallpapers">
                        {wallpapers.map((wallpaper) => {
                            return (
                                <Link
                                    key={wallpaper.slug}
                                    to={wallpaper.slug}
                                >
                                    <Photo
                                        key={wallpaper.slug}
                                        image={`https:${wallpaper.image.fields.file.url}`}
                                        imageAlt={wallpaper.title}
                                    />
                                    <span>{wallpaper.title}</span>
                                </Link>
                            )
                        })}
                    </div>
                </div>

                <div className="not-here">
                    <h2>Collection not listed here?</h2>
                    <p>Reach out to inquire about adding it to the list.</p>
                    <Link
                        className="btn reach-out"
                        to="https://www.x.com/tokensnapxyz"
                    ><FontAwesomeIcon icon="fa-solid fa-bullhorn" />Reach Out!</Link>
                </div>
            </div>

            <Footer />
        </div>
    )
};

export default Wallpapers;