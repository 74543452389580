import React, { useState, useEffect } from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Photo from '../components/Photo';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function Home() {
  const [pages, setPages] = useState([]);

  useEffect(() => {
    const storedPages = localStorage.getItem('pagesData');
    if (storedPages) {
      setPages(JSON.parse(storedPages));
    }
  }, []);

  return (
    <div className="page-wrap home">
      <Header />
      <div className="home-inner container">
        <h1 className="home">Unlock the Art of <strong>NFTs</strong></h1>
        <p>Welcome to TokenSnap, your window into the mesmerizing world of NFT art and collectibles. <strong>Choose your collection below to get started!</strong></p>

        <div className="home-boxes">
          {pages.map((page) => {
            return (
              <Link
                key={page.slug}
                to={page.slug}
              >
                <Photo
                  key={page.slug}
                  image={`https:${page.image.fields.file.url}`}
                  imageAlt={page.title}
                />
                <span>{page.title}</span>
              </Link>
            )
          })}
        </div>

        <div className="not-here">
          <h2>Wallpapers more your thing?</h2>
          <p>Check out our RareBoy-inspired Wallpaper generators.</p>
          <Link 
            className="btn reach-out"
            to="/wallpapers"
          ><FontAwesomeIcon icon="fa-solid fa-mobile-screen-button" />Lets Go!</Link>
        </div>
      </div>

      <Footer />
    </div>
  )
};

export default Home;