import React, { useState, useEffect } from 'react';
import Asset from './Asset';
import axios from 'axios';
function WalletContents(props) {
  const [error, setError] = useState('');
  const [assets, setAssets] = useState('');

  const userAddress = props.wallet;
  const network = props.network;
  const apiKey = props.apikey;
  const contractSlug = props.contractslug;
  const contract = props.contract;

  let rares = null;
  let oneoffs = null;

  if(props.wallpaper) {
    rares = props.wallpaper.rares;
    oneoffs = props.wallpaper.oneoffs;
  }

  // Function to fetch Ethereum NFTs from OpenSea
  const fetchEthereumAssets = () => {
    const walletOptions = {
      method: 'GET',
      url: 'https://api.opensea.io/api/v2/chain/ethereum/account/' + userAddress + '/nfts?collection=' + contractSlug,
      params: {
        limit: '200',
      },
      headers: {
        'X-API-KEY': apiKey,
        'Content-Type': 'application/json',
      },
    };

    axios
      .request(walletOptions)
      .then(function (response) {
        setAssets(response.data.nfts);

        if (response.data.nfts.length === 0) {
          setError('Please switch to the ETH option and reconnect your wallet.');
        }
      })
      .catch(function (error) {
        setError('Error getting assets from Ethereum wallet. ' + error);
      });
  };

  // Function to fetch Solana NFTs using Magic Eden API
  const fetchSolanaAssets = () => {
    const proxyUrl = `http://localhost:5001/proxy/magic-eden?address=${userAddress}&collection_symbol=${contractSlug}&limit=50`;

    axios
      .get(proxyUrl)
      .then((response) => {
        setAssets(response.data);

        if (response.data.length === 0) {
          setError('No assets in your Solana wallet.');
        }
      })
      .catch((error) => {
        setError('Please switch to the SOL option and reconnect your wallet.');
      });
  };

  // Fetch assets based on the selected network
  useEffect(() => {
    if (network === 'ethereum') {
      fetchEthereumAssets();
    } else if (network === 'solana') {
      fetchSolanaAssets();
    }
  }, [network, userAddress]);

  return (
    <div className="wallet-contents">
      {error && <p className="throw_error">{error}</p>}
      {assets.length >= 1 &&
        assets.map((asset) => {
          return (
            <Asset
              key={asset.identifier || asset.mintAddress}
              id={asset.identifier || asset.mintAddress}
              asset={asset}
              image={asset.image_url || asset.image}
              size={props.size}
              component={props.component}
              imageURL={props.imageURL}
              setImageURL={props.setImageURL}
              plainImage={props.plainImage}
              setPlainImage={props.setPlainImage}
              toggled={props.toggled}
              trait={props.trait}
              setToggle={props.setToggle}
              savedTrait={props.savedTrait}
              setSavedTrait={props.setSavedTrait}
              contract={contract}
              apiKey={apiKey}
              gm={props.gm}
              network={network}
              wallpaper={props.wallpaper}
              rares={rares}
              oneoffs={oneoffs}
            />
          );
        })}
    </div>
  );
}

export default WalletContents;