import React from 'react';

function Footer() {
  return (
    <footer>
        <p>@2024 All Rights Reserved. Created and developed by <a href="https://www.twitter.com/0xwebninja">WebNinja</a></p>
    </footer>
  );
}

export default Footer;